/** @jsx jsx */
import React, { useState, useMemo } from "react";
import { graphql } from "gatsby";
import {jsx, Container, Box, Flex, Heading} from 'theme-ui';
import Layout from '../components/Layout';
import CardWrapper from "../components/shared/CardWrapper";
import Pagination from "../components/shared/pagination/Pagination";
import SearchComponent from "../components/shared/SearchComponent";
import ContentfulRichText from "../components/shared/ContentfulRichText";
import HeroSection from "../components/sections/heroSection";
import { BgImage } from "gbimage-bridge";


export const pageQuery = graphql`
  query AuthorPageQuery($id: String) {
    contentfulAuthor(id: { eq: $id })  {
      id
      internalName
      name
      slug
      description {
        raw
      }
    }
    allContentfulBlogPost(
      filter: {
        postType: {in: ["blog", "blog-mdx"]},
        isHidden: {ne: true}
      }
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          id
          slug
          title
          author
          authorReference {
              id
          }
          description
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            gatsbyImageData(width:372)
          }
          featuredImageAltText
        }
      }
      totalCount
    }
    allContentfulPage(filter: {slug: {eq: "authors-list"}}) {
      nodes {
        id
        sections {
          ... on ContentfulHeroSection {
            ...ContentfulHeroSectionFragment
          }
        }
      }
    }
    allContentfulAsset(filter: {title: {eq: "bg-data-hexagons"}}) {
      edges {
        node {
          id
          gatsbyImageData
          title
        }
      }
    }
    file(relativePath: {eq: "background-pattern.png"}) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 2000,
        )
      }
    }
  }
`;

export default function AuthorPageTemplate ({ data }) {
  const posts = [...data.allContentfulBlogPost.edges];
  const authorName = data.contentfulAuthor.internalName;
  const authorDescription = data.contentfulAuthor.description;
  const authorPosts = posts ? posts.filter(
    post => post.node.author === authorName || post.node.authorReference?.id === data.contentfulAuthor.id
  ) : [];
  const { sections } = data.allContentfulPage.nodes && data.allContentfulPage.nodes.length > 0 ? data.allContentfulPage.nodes[0] : [];

  const pageNumberLimit = 9;
    const paginationLimit = 6;
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPageLimit, setMaxPageLimit] = useState(5);
    const [minPageLimit, setMinPageLimit] = useState(0);

    const onPageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const onPrevClick = () => {
      if ((currentPage - 1) <= minPageLimit) {
        setMaxPageLimit(maxPageLimit - (paginationLimit - 1));
        setMinPageLimit(minPageLimit - (paginationLimit - 1));
      }

      setCurrentPage(prev => prev - 1);
    };

    const onNextClick = () => {
      if (currentPage + 1 > maxPageLimit) {
        setMaxPageLimit(maxPageLimit + (paginationLimit - 1));
        setMinPageLimit(minPageLimit + (paginationLimit - 1));
      }

      setCurrentPage(prev => prev + 1);
    };

    const paginatedPosts = useMemo(() => {
      return authorPosts.slice(((currentPage - 1) * pageNumberLimit), ((currentPage - 1) * pageNumberLimit) + pageNumberLimit);
    }, [authorPosts, currentPage, pageNumberLimit]);


    const paginationAttributes = {
      currentPage,
      maxPageLimit,
      minPageLimit,
      totalPages: Math.ceil((authorPosts.length) / pageNumberLimit)
    };

    const backgroundImage = data.allContentfulAsset.edges[0].node;

  return (
    <Layout>
      <BgImage image={backgroundImage?.gatsbyImageData} sx={{ marginInline: "auto" }} className="author-hero">
        <Container className="hero-main-container">
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: ['center', null, 'space-between'],
            alignItems: 'start',
            padding: 0,
            my: [2, 4],
          }}>
            {authorName &&
              <Flex sx={{ padding: ['20px 0 20px 0', '20px 0 20px 0'], textAlign: 'center', justifyContent: 'center', alignItems: 'start', flexDirection: 'column-reverse' }}>
                <Heading as="h1" sx={{ fontWeight: 'bold' }}>{authorName}</Heading>
                <Heading as="h2" sx={{ fontWeight: '400', fontSize: '32px !important', color: '#707070' }}>{`All posts by`}</Heading>
              </Flex>
            }
            {authorDescription &&
              <Flex sx={{ padding: ['20px 0 24px 0', '0px 0 24px 0'], textAlign: 'left', justifyContent: 'start', alignItems: 'center' }}>
                <Heading as="h2" sx={{ fontWeight: '100', fontSize: '32px !important', color: '#707070' }}>
                  <Heading as="h2" sx={{ fontWeight: '100', fontSize: '32px !important', color: '#707070' }}>
                    <ContentfulRichText richText={authorDescription} />
                  </Heading>
                </Heading>
              </Flex>
            }
          </Box>
        </Container>
      </BgImage>

      <Box sx={{ position: 'relative', pt: [4], backgroundColor: "#F4F4F7", pb: [4] }}>
        <Container>
          {paginatedPosts.length > 0 ? (
            <>
              <Box sx={{
                display: 'flex',
                rowGap: "30px",
                listStyle: 'none',
                flexWrap: 'wrap',
                justifyContent: ['center', null, 'space-between'],
                alignItems: 'center',
                padding: 0,
                my: [2, 4],
                '&::after': {
                  content: '""',
                  flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%']
                }
              }}>
                {paginatedPosts.map(({ node: post }) => (
                  <Box
                    key={post.id}
                    sx={{
                      flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%'],
                      display: 'flex',
                      margin: '6px 0',
                    }}
                  >
                    <CardWrapper
                      item={{
                        heading: post?.frontmatter?.title || post?.title,
                        image: post?.frontmatter?.featuredImage?.childImageSharp || post?.featuredImage,
                        imageAltText: post?.frontmatter?.title || post?.featuredImageAltText,
                        linkUrl: `/blog/${post.slug}`,
                        date: post?.frontmatter?.date || post?.date,
                        description: post?.frontmatter?.description || post?.description,
                        cardHeight: '520px',
                      }}
                    />
                  </Box>
                ))}
              </Box>
              <Pagination
                {...paginationAttributes}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
                onPageChange={onPageChange}
              />
            </>
          ) : (
            <h2>No published posts authored by {data.contentfulAuthor.name}</h2>
          )}

        </Container>
        <SearchComponent dataFor="blog"/>
      </Box>
      {sections && sections.length > 0  && <HeroSection sectionContent={sections[0]} />}
    </Layout>
  );
};